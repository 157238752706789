import { createVNode as _createVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "d-flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("form", _hoisted_1, [
    _createVNode("button", {
      class: "btn btn-secondary me-2 btn-sm",
      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => ($setup.previous && $setup.previous(...args)))
    }, "Previous"),
    _withDirectives(_createVNode("input", {
      class: "form-control me-2",
      type: "search",
      placeholder: "請填入欲前往之頁數",
      "aria-label": "Search",
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ($setup.articlePage = $event))
    }, null, 512), [
      [_vModelText, $setup.articlePage]
    ]),
    _createVNode("button", {
      class: "btn btn-outline-success me-2",
      type: "submit",
      onClick: _cache[3] || (_cache[3] = ($event: any) => (
        $setup.editorFn($setup.selectLang, $setup.selectValue.enable, $setup.selectValue.published, $setup.articlePage)
      ))
    }, " Submit "),
    _createVNode("button", {
      class: "btn btn-secondary btn-sm",
      onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => ($setup.next && $setup.next(...args)))
    }, "Next")
  ]))
}